<template>
  <b-card
    no-body
    class="badge-path d-inline-flex flex-row align-items-center p-2 border-0 align-middle"
  >
    <b-card-body class="d-inline-flex flex-row p-0">
      <icon-series class="mr-2" />
      <b-card-text class="badge-path__text font-weight-bold w-color-white">
        Príručka
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  components: {
    "icon-series": () => import("/assets/icons/iconHandbook.svg?inline"),
  },
};
</script>

<style lang="scss" scoped>
.badge-path {
  max-width: 120px;
  background-color: var(--a-color-primary);
  border-radius: 4px;

  .badge-path__text {
    font-size: 0.875rem;
  }
}

.icon-series {
  width: 20px;
  height: 20px;
}
</style>
